import { userAxios } from '../../index'

const treatmentList = async paginate => {
  try {
    return await userAxios
      .get(`insurance/treatments/all/${paginate}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const treatmentCreate = async data => {
  try {
    return await userAxios.post('insurance/treatments', data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const treatmentUpdate = async (id, data) => {
  try {
    return await userAxios.put(`insurance/treatments/${id}`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const treatmentDelete = async id => {
  try {
    return await userAxios.delete(`insurance/treatments/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const treatmentPagination = async (perPage, page) => {
  try {
    return await userAxios
      .get(`insurance/treatments/all/${perPage}?page=${page}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const treatmentFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/treatments/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const treatmentFilterPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`insurance/treatments/search/${perPage}?page=${page}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  treatmentList,
  treatmentCreate,
  treatmentUpdate,
  treatmentDelete,
  treatmentPagination,
  treatmentFilter,
  treatmentFilterPagination,
}
